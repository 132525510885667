import React, { Component, Fragment } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import GenBillingAddress from './GenBillingAddress.jsx';
import Button from '@material-ui/core/Button';
import GenBillTwo from './GenBillTwo.jsx';
import FormBilling from './FormBilling.jsx';
import menuVertical from '~/images/icons/menu_vertical.png';
import { actions as officeSettingsActions } from '~/app/redux/modules/officeSettings.js';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
import { actions as notificationActions } from '~/app/redux/notifications/duck';
import BillingDiscounts from './BillingDiscounts.jsx';
import CustomChanges from './CustomChanges.jsx';
import CustomSettings from './CustomSettings.jsx';
import PPRCharges from '~/app/Pages/Setup/BillingSetup/components/PPRCharges.jsx';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import {
  REQUIRED_SETUP_PAGES,
  NOTIFICATION_TYPE,
  ADDRESS_DATA_STRINGS,
  DFLT,
  DFL2,
  BILLING_ITEM_TYPE,
  BILLING_FORM_TYPES,
} from '~/app/constants.js';
import XlinkAPI from '../../../api/xlinkAPI';
import {
  NativeSelect,
  Menu,
  MenuItem,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Snackbar,
} from '@material-ui/core';
import ErrorHelpers from '~/app/errorHelpers.js';
import AccessControl from '#/Auth/AccessControl.jsx';
import EfinFees from './EfinFees.jsx';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';

const mapStateToProps = state => {
  return {
    officeAddress: state.officeSettings.officeAddress,
  };
};

const mapDispatchToProps = {
  ...officeSettingsActions,
  ...setupPageActions,
  ...notificationActions,
};

const styles = theme => ({
  btnBorderLeftNone: {
    borderLeft: 'none',
    marginBottom: '0px',
    borderRadius: '0px 4px 4px 0px',
    backgroundColor: '#e3ecf5',
    minWidth: '0.5rem',
    maxWidth: '1.0rem',
    marginTop: '5px',
    height: '30px',
    border: '1px solid #8FC3FF', // Overriding the button color
  },
  noBorderOnFocus: {
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
  },
});

const messageBillingSetup =
  "You must first add a new billing scheme before continuing. \n\nPlease click the button next to 'Set as Default' to name and save your new Billing scheme.";

const messageStateTax =
  'Preparer is responsible for charging and collecting applicable state and/or local taxes on any and all fees that are charged or passed through to the taxpayer, in accordance with Preparer’s state and/or tax regulations';

class GenBillingSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      season: WebHelpers.getJWTPayload().season,
      expanded: true,
      sameAsOffice: this.props.billingInfo.sameAsOffice === 1,
      billingSchemes:
        this.props.billingInfo.billingSchemes === null ? [] : this.props.billingInfo.billingSchemes,
      tbRowsFormBilling:
        this.props.billingInfo.billingItems === null ? [] : this.props.billingInfo.billingItems,
      selectedScheme:
        this.props.billingInfo.billing_id === null ? 0 : this.props.billingInfo.billing_id,
      isDefault: this.props.billingInfo.is_default,
      tbRowsDiscounts:
        this.props.billingInfo.discountItems === null ? [] : this.props.billingInfo.discountItems,
      customCodeDiscounts:
        this.props.billingInfo.discountCustomItems === null
          ? []
          : this.props.billingInfo.discountCustomItems,
      tbRowsCustomCharges:
        this.props.billingInfo.customItems === null ? [] : this.props.billingInfo.customItems,
      tbRowsCustomSettings: [],
      loading: false,
      anchorEl: null,
      addNewSchemeDialog: false,
      address: this.props.billingInfo.address === null ? {} : this.props.billingInfo.address,
      billOptsAndRate:
        this.props.billingInfo.config === null
          ? {}
          : this.mapBillOptsAndRate(this.props.billingInfo.config),
      itemDialogInfoContent: '',
      itemDialogInfoTitle: 'Information',
      itemDialogInfoOpen: false,
      confirmCancel: false,
      gotoPrev: false,
      snackbarOpen: false,
      actionsLocked: this.props.billingInfo.billing_id !== 0, // If selectedScheme == 0 then we lock actions. Meaning there is no billing item.
      show: false,
      efinfees: this.props.billingInfo.efinfees === null ? {} : this.props.billingInfo.efinfees,
      hasStateTaxRateChange: false,
      isStateTaxDisclaimerOpen: false,
      shouldGoToNextPage: false,
      currentStateTaxRate:
        this.props?.billingInfo?.config[0] === null
          ? ''
          : this.props.billingInfo.config[0].configValue,
    };
  }

  componentDidMount = async () => {
    // Cylce through billing schemes and check if one of them is default
    // If so show the notification that the billing setup was completed.
    // If no default billing scheme was detected then we are going to throw back a notification telling them one
    // must be selected to complete the billing section of the office setup.
    const defaultBillingScheme = this.state.billingSchemes.filter(scheme => {
      return scheme.is_default === 1; // There really should be only one entry
    });
    if (defaultBillingScheme.length < 1) {
      this.props.addFrontendNotification({
        id: 0,
        returnID: 0,
        content: 'A billing scheme must be set as default to complete the required office setup',
        type: NOTIFICATION_TYPE.WARNING,
      });
    }

    try {
      const res = await XlinkAPI.getUserCustomTags();
      if (statusOK(res)) {
        this.setState({ customTags: res.data });
      }
    } catch (err) {
      ErrorHelpers.handleError('Unable to retrieve discount custom information. ', err);
    }
  };

  // mapBillOptsAndRate will take in an array of billing options return a mapped
  mapBillOptsAndRate = config => {
    const billItems = {};
    config.forEach(item => {
      billItems[item.configName] = this.evalDataType(item.configType, item.configValue);
    });
    return billItems;
  };

  evalDataType = (valType, val) => {
    if (valType === 1) {
      return val;
    } else if (valType === 3) {
      if (val === 'true') return true;
      else return false;
    }
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleChange = name => event => {
    if (this.state.selectedScheme === 0) {
      this.setState({ show: true });
      return;
    }
    if (event.target.checked) {
      this.setState({
        address: this.props.officeAddress,
        [name]: event.target.checked,
      });
    } else {
      this.setState({
        [name]: event.target.checked,
        address: {},
      });
    }
  };

  removeExtraSpaces = (obj, objKey, value) => {
    let valTrim = value.replace(/\s\s+/g, ' '); // Replace tabs, new tabs and multiple spacing with single blank space
    valTrim = valTrim.trim();
    if (this.state.selectedScheme === 0) {
      this.setState({ show: true });
      return;
    }
    this.setState(prevState => ({
      [obj]: {
        ...prevState[obj],
        [objKey]: valTrim,
      },
    }));
  };

  handleObjectChange = (obj, objKey, value) => {
    if (objKey === ADDRESS_DATA_STRINGS.SUITE_NUM_STRING) {
      // Checks for alphanumeric value or "#", and allows for blank spaces
      value = value.replace(/[^#a-z0-9 ]+/gi, '');
    }
    if (
      objKey === ADDRESS_DATA_STRINGS.FIRM_NAME_STRING ||
      objKey === ADDRESS_DATA_STRINGS.FIRM_ADDRESS_STRING ||
      objKey === ADDRESS_DATA_STRINGS.SUITE_NUM_STRING
    ) {
      value = value.toUpperCase();
    } else if (objKey === ADDRESS_DATA_STRINGS.CITY_STRING) {
      value = value.toUpperCase();
      value = value.replace(/[0-9]/g, ''); // Remove digits[0-9]
    }
    if (this.state.selectedScheme === 0) {
      this.setState({ show: true });
      return;
    }
    if (objKey === 'stateSalesTax') {
      this.setState({ hasStateTaxRateChange: value !== this.state.currentStateTaxRate });
    }
    // Stringifying boolean value to save properly
    if (objKey === 'bankDisbursementOption') {
      value = value.toString();
    }
    this.setState(prevState => ({
      [obj]: {
        ...prevState[obj],
        [objKey]: value,
      },
    }));
  };

  updateTbRowsFormBilling = data => {
    this.setState({
      tbRowsFormBilling: data,
    });
  };

  handleTBFormRowDel = (index, arrTable, itemKey, itemType) => {
    XlinkAPI.handleFormRowDeletion(index, this.state.selectedScheme, itemType, this.props.loginID)
      .then(() => {
        this.setState({
          [arrTable]: this.state[arrTable].filter(item => {
            return item[itemKey] !== index;
          }),
        });
      })
      .catch(err => {
        ErrorHelpers.handleError('Row Deletion Error', err);
      });
  };

  handleRowDeletionDiscount(index) {
    XlinkAPI.handleFormRowDeletion(index, this.state.selectedScheme, this.props.loginID)
      .then(() => {
        this.setState({
          tbRowsDiscounts: this.state.tbRowsDiscounts.filter(item => {
            return item.discounts_id !== index;
          }),
        });
      })
      .catch(err => {
        ErrorHelpers.handleError('Row Deletion Error', err);
      });
  }

  handleChangeTbForms = (id, dataType, value) => {
    if (this.state.selectedScheme === 0) {
      this.setState({ show: true });
      return;
    }
    value = value.replace(/[$,]/g, '');
    const newState = this.state.tbRowsFormBilling.map(item => {
      if (item.billing_lookup_id === id) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    this.setState({
      tbRowsFormBilling: newState,
    });
  };

  addRow = (rowObject, table) => {
    this.setState(prevState => {
      return { [table]: [...prevState[table], rowObject] };
    });
  };

  handleChangeTableCustom = (index, dataType, value) => {
    if (this.state.selectedScheme === 0) {
      this.setState({ show: true });
      return;
    }
    const newState = this.state.tbRowsCustomCharges.map((item, i) => {
      if (i === index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    this.setState({
      tbRowsCustomCharges: newState,
    });
  };

  handleChangeTableDiscount = (index, dataType, value) => {
    if (this.state.selectedScheme === 0) {
      this.setState({ show: true });
      return;
    }
    const newState = this.state.tbRowsDiscounts.map((item, i) => {
      if (i === index && dataType === 'discounts_type_id') {
        return { ...item, [dataType]: value, discount_amount: '' };
      } else if (i === index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    this.setState({
      tbRowsDiscounts: newState,
    });
  };

  handleUpdateCustomCodeDiscount = (discountID, value, discountCode) => {
    if (this.state.selectedScheme === 0) {
      this.setState({ show: true });
      return;
    }

    // if existing entry, update
    const newState = this.state.customCodeDiscounts.map((item, i) => {
      if (item.discount_code === discountCode) {
        return { ...item, discount_amount: value };
      }
      return item;
    });

    // new entry push into state
    if (discountID === null) {
      newState.push({
        discount_code: discountCode,
        discount_amount: value,
        discounts_type_id: 2,
        discounts_id: 0,
      });
    }
    this.setState({
      customCodeDiscounts: newState,
    });
  };

  getDiscountTypeByKey = key => {
    return this.state.tbRowsDiscounts[key].discounts_type_id;
  };

  handleSaveSetup() {
    if (
      this.props.officeSetupProgressFlags.office === true &&
      this.props.officeSetupProgressFlags.ero === true &&
      this.props.officeSetupProgressFlags.preparer === true &&
      this.props.officeSetupProgressFlags.billing === false
    ) {
      this.props.addFrontendNotification({
        id: 0,
        returnID: 0,
        content: 'Required Office Setup Completed Successfully',
        type: NOTIFICATION_TYPE.SUCCESS,
      });
      this.props.onSetupComplete();
    }
    this.props.updateOfficeSetupFlag('billing', true);
  }

  validateAddressFields = () => {
    // Check if empty address obj
    if (!this.state?.address || Object.keys(this.state.address).length === 0) {
      return false;
    }

    // If same as office, do nothing (valid)
    if (this.state?.sameAsOffice) {
      return true;
    }

    // Non-required fields
    const nonReqFields = ['fax', 'suiteNum'];

    // Run check that required fields are filled out
    return Object.keys(this.state.address).every(key => {
      if (!nonReqFields.includes(key) && this.state.address[key] === '') {
        return false;
      }
      return true;
    });
  };

  handleBillingSave = shouldGoToNextPage => {
    if (this.state.hasStateTaxRateChange) {
      this.setState({
        isStateTaxDisclaimerOpen: true,
        shouldGoToNextPage,
      });
    } else this.upsertBillingScheme(shouldGoToNextPage);
  };

  upsertBillingScheme = gotoNext => {
    if (this.state.isStateTaxDisclaimerOpen) {
      this.setState({ isStateTaxDisclaimerOpen: false, hasStateTaxRateChange: false });
    }
    // Validate office has an address to save.
    if (!this.validateAddressFields()) {
      this.setState({
        itemDialogInfoTitle: 'Fields Required',
        itemDialogInfoOpen: true,
        itemDialogInfoContent: 'Please revise address entered on billing form.',
      });
      return;
    }

    // check for special case, we do not want customers to use reserved custom codes or create empty discount
    for (let i = 0; i < this.state.tbRowsDiscounts?.length; i++) {
      const errTitle = 'Unable to save billing information';
      // check for reserved codes
      if (
        this.state.tbRowsDiscounts[i].discount_code?.toUpperCase() === DFLT ||
        this.state.tbRowsDiscounts[i].discount_code?.toUpperCase() === DFL2
      ) {
        ErrorHelpers.handleError(
          errTitle,
          ErrorHelpers.createSimpleError(
            `Discount code can not be ${DFLT} or ${DFL2}. Please rename your discount and try again`,
          ),
        );
        return;
        // check for empty amounts
      } else if (
        !this.state.tbRowsDiscounts[i]?.discount_amount ||
        !this.state.tbRowsDiscounts[i]?.discount_code
      ) {
        ErrorHelpers.handleError(
          errTitle,
          ErrorHelpers.createSimpleError(
            'A discount was created without setting a code, amount or both. Please update or delete this discount to continue',
          ),
        );
        return;
      }
    }

    XlinkAPI.upsertBillingScheme(
      this.state.tbRowsFormBilling,
      this.state.tbRowsDiscounts,
      this.state.tbRowsCustomCharges,
      this.state.address,
      this.state.billOptsAndRate,
      this.state.sameAsOffice,
      this.state.selectedScheme,
      this.props.loginID,
      this.state.customCodeDiscounts,
    )
      .then(res => {
        this.handleSaveSetup();
        this.setState({
          currentStateTaxRate: this.state.billOptsAndRate.stateSalesTax,
        });
        if (gotoNext) {
          this.props.loadSetupComponent(REQUIRED_SETUP_PAGES.LICENCING.NAME);
        } else {
          this.toggleSuccessSnackbar(true);
          // Refetch billing scheme after to get the IDs of the newly added elements.
          this.getSelectedScheme(this.state.selectedScheme);
        }
      })
      .catch(error => {
        ErrorHelpers.handleError('Error Updating Billing Scheme', error);
      });
  };

  setDefaultBillingScheme = () => {
    if (this.state.selectedScheme === 0) {
      alert(
        "Unable to set default billing scheme. Please add a new billing scheme and try again. \n\nClick the button next to 'Set as Default' to name and save your new Billing scheme.",
      );
      return;
    } else if (this.state.isDefault === 1) {
      return;
    }
    XlinkAPI.setDefaultBillingScheme(this.state.selectedScheme, this.props.loginID)
      .then(res => {
        this.setState({
          billingSchemes: res.data,
        });
      })
      .catch(error => {
        ErrorHelpers.handleError('Unable to Set Default Billing Scheme', error);
      });
  };

  generateBillingSchemes = () => {
    return (
      <NativeSelect
        id="selectedSchemeList"
        value={this.state.selectedScheme}
        onChange={e => this.handleSelectedSchemeChange(e.target.value)}
        style={{
          borderRight: 'none',
          borderRadius: '4px 0px 0px 4px',
        }}
        disableUnderline={true}
      >
        {this.state.billingSchemes.map(scheme => {
          let isDefault = '';
          if (scheme.is_default) {
            isDefault = '(Default)';
          }
          return (
            <option key={scheme.billing_id} value={scheme.billing_id}>
              {scheme.billing_scheme_name} {isDefault}
            </option>
          );
        })}
      </NativeSelect>
    );
  };

  renderAddSchemeDialogContent = () => {
    return (
      <Fragment>
        <DialogTitle id="dlgAddSchemeTitle">Create New Scheme</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter a new billing scheme name</DialogContentText>
          <TextField
            autoFocus={true}
            margin="dense"
            id="txtAddSchemeDlg"
            label="Enter Scheme Name"
            variant="filled"
            value={this.state.newSchemeName}
            fullWidth
            onChange={e => this.setState({ newSchemeName: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={this.handleAddSchemeDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleAddNewScheme} color="primary">
              Add
            </Button>
          </div>
        </DialogActions>
      </Fragment>
    );
  };

  renderInfoDialogContent = () => {
    return (
      <Fragment>
        <DialogTitle id="dlgInfoDialogTitle">{this.state.itemDialogInfoTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.state.itemDialogInfoContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleInfoDialogClose}>Close</Button>
        </DialogActions>
      </Fragment>
    );
  };

  getSelectedScheme = value => {
    XlinkAPI.getBillingOfficeInfo(value, this.props.loginID) // Pull the default billing scheme.
      .then(res => {
        this.setState({
          tbRowsFormBilling: res.data.billingItems == null ? [] : res.data.billingItems,
          billingSchemes: res.data.billingSchemes == null ? [] : res.data.billingSchemes,
          tbRowsDiscounts: res.data.discountItems == null ? [] : res.data.discountItems,
          tbRowsCustomCharges: res.data.customItems == null ? [] : res.data.customItems,
          isDefault: res.data.is_default,
          tbRowsCustomSettings: [],
          address: res.data.address == null ? {} : res.data.address,
          billOptsAndRate: res.data.config == null ? {} : this.mapBillOptsAndRate(res.data.config),
          loading: false,
          sameAsOffice: res.data.sameAsOffice === 1,
        });
      })
      .catch(err => {
        ErrorHelpers.handleError('Error Fetching Billing Office Info', err);
      });
  };

  handleSelectedSchemeChange = value => {
    this.setState({ selectedScheme: value, loading: true }, this.getSelectedScheme(value));
  };

  handleContextMenuOpen = event => {
    event.preventDefault();
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleContextMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleContextMenuAddScheme = () => {
    this.setState(
      {
        addNewSchemeDialog: true,
      },
      this.handleContextMenuClose(),
    );
  };

  handleAddSchemeDialogClose = () => {
    this.setState({
      addNewSchemeDialog: false,
    });
  };

  // handleAddNewScheme will Change the Billing Id and clear the state.
  // We will set the billingID to -1 to indicate to the API that we are adding a new billingScheme
  handleAddNewScheme = () => {
    const row = {
      billing_id: 0,
      billing_scheme_name: this.state.newSchemeName,
    };
    XlinkAPI.addNewBillingScheme(this.state.newSchemeName, this.props.loginID)
      .then(res => {
        row.billing_id = res.data.billingID;
        row.is_default = res.data.billing.is_default;
        this.setState(prevState => {
          return {
            billingSchemes: [...prevState.billingSchemes, row],
            selectedScheme: row.billing_id,
            isDefault: res.data.billing.is_default,
            tbRowsFormBilling:
              res.data.billing.billingItems == null ? [] : res.data.billing.billingItems,
            tbRowsDiscounts:
              res.data.billing.discountItems == null ? [] : res.data.billing.discountItems,
            tbRowsCustomCharges:
              res.data.billing.customItems == null ? [] : res.data.billing.customItems,
            tbRowsCustomSettings: [],
            address: res.data.billing.address,
            billOptsAndRate:
              res.data.billing.config == null
                ? {}
                : this.mapBillOptsAndRate(res.data.billing.config),
          };
        }, this.handleAddSchemeDialogClose());
      })
      .catch(err => {
        ErrorHelpers.handleError('Error Adding New Scheme', err);
      });
  };

  handleContextMenuDeleteScheme = billingid => {
    if (this.state.isDefault === 1) {
      this.setState({ anchorEl: null });
      alert(
        'Unable to delete default billing scheme. Please set a different billing scheme as default and try again.',
      );
      return;
    }

    XlinkAPI.handleBillingSchemeDeletion(billingid, this.props.loginID)
      .then(res => {
        this.setState(
          {
            billingSchemes: this.state.billingSchemes.filter(billid => {
              return billid.billing_id !== billingid;
            }),
            anchorEl: null,
          },
          () => {
            if (this.state.billingSchemes.length > 0) {
              this.getSelectedScheme(this.state.selectedScheme);
            } else {
              this.clearScheme();
            }
          },
        );
      })
      .catch(error => {
        ErrorHelpers.handleError('Error Deleting Billing Scheme', error);
      });
  };

  clearScheme = () => {
    this.setState({
      sameAsOffice: false,
      billingSchemes: [],
      tbRowsFormBilling: [],
      selectedScheme: 0,
      tbRowsDiscounts: [],
      tbRowsCustomCharges: [],
      tbRowsCustomSettings: [],
      address: {},
      billOptsAndRate: {},
    });
  };

  // TODO --> Change this and maybe just query by a different way instead of by itemType
  // This isn't a good approach.
  openInfoDialog = itemType => {
    let itemDialogInfoContent = '';
    const itemDialogInfoOpen = true;
    switch (itemType) {
      case BILLING_ITEM_TYPE.EFIN:
        itemDialogInfoContent = 'Fees will populate from bank enrollment application.';
        break;
      case BILLING_ITEM_TYPE.CUSTOM:
        itemDialogInfoContent =
          'Enter pre-set charges to display on line 6 under ERO Fees on the Invoice.';
        break;
      case BILLING_ITEM_TYPE.DISCOUNT:
        itemDialogInfoContent =
          'Enter pre-set discounts to display on line 2 under Discounts & Credits on the Invoice.';
        break;
      case BILLING_FORM_TYPES.WORKSHEET:
        itemDialogInfoContent =
          'Enter a price for each worksheet, and it will display on the Invoice when the worksheet is added to the tax return.';
        break;
      case BILLING_FORM_TYPES.LINEITEM:
        itemDialogInfoContent =
          'Enter a price on each form line and it will display on the Invoice when the form is added to the tax return.';
        break;
      case BILLING_FORM_TYPES.FORM:
        itemDialogInfoContent =
          'Enter a price on each form line and it will display on the Invoice when the form is added to the tax return.';
        break;
      default:
        itemDialogInfoContent = false;
    }
    this.setState({
      itemDialogInfoOpen,
      itemDialogInfoTitle: 'Information',
      itemDialogInfoContent,
    });
  };

  handleInfoDialogClose = () => {
    this.setState({
      itemDialogInfoOpen: false,
    });
  };

  handleStateDisclaimerDialogClose = () => {
    this.setState({
      isStateTaxDisclaimerOpen: false,
    });
  };

  toggleSuccessSnackbar = toggle => {
    this.setState({
      snackbarOpen: toggle,
    });
  };

  // toggles the confirm cancellation dialog.
  // toggle- hide/show cancellation window
  toggleConfirmCancel = toggle => {
    this.setState({
      confirmCancel: toggle,
    });
  };

  cancelShow = () => {
    this.setState({ show: false });
  };

  render() {
    const sameInfoValue = this.state.sameAsOffice ? 'true' : 'false';
    const { anchorEl } = this.state;
    const { classes } = this.props;

    return (
      <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={2500}
          onClose={() => this.toggleSuccessSnackbar(false)}
          id="snkbarconfsaved"
          message={<span id="success-message">Configuration Saved</span>}
        />
        <Paper>
          <form style={{ marginLeft: '3vw', marginRight: '3vw', marginTop: '1vh' }}>
            <div
              style={{
                textAlign: 'left',
                paddingTop: '3vh',
                paddingBottom: '1vh',
              }}
            >
              <div style={{ float: 'left' }}>
                <IconButton
                  id="icobtnExpandMoreLess"
                  style={{ width: '1em', height: '1em' }}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  classes={{ root: classes.noBorderOnFocus }}
                >
                  {this.state.expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '21px',
                    letterSpacing: '0.14px',
                    color: '#1A173B',
                    marginLeft: '0.3em',
                  }}
                >
                  Billing General Office Info
                </div>
                <div style={{ float: 'right', height: '35px', display: 'flex' }}>
                  <AccessControl
                    requiredAction="write"
                    accessLevel="add/edit_billing"
                    disableOnFalse={true}
                  >
                    <Button
                      id="brnSetAsDefaultBillingSetup"
                      color="primary"
                      onClick={() => this.setDefaultBillingScheme()}
                      style={{
                        border: 'none',
                        width: '10vw',
                        padding: '0',
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '0.25vw',
                        minHeight: '35px',
                        // For some reason minHeight for button and input elements is 36.
                        // Set minHeight to 35 to match input height we set in GenericTheme.jsx
                        // This is a bandaid fix, theming needs to be fixed to resolve these types of issues properly.
                      }}
                    >
                      Set as Default
                    </Button>
                  </AccessControl>
                  {this.generateBillingSchemes()}
                  <AccessControl
                    requiredAction="write"
                    accessLevel="add/edit_billing"
                    disableOnFalse={true}
                  >
                    <Button
                      className="contextMenuButton"
                      aria-haspopup="true"
                      aria-owns={anchorEl ? 'simple-menu' : null}
                      onClick={this.handleContextMenuOpen}
                      classes={{ root: classes.btnBorderLeftNone }}
                      style={{
                        marginTop: '5px',
                        marginBottom: '5px',
                        minHeight: '35px',
                        // For some reason minHeight for button and input elements is 36.
                        // Set minHeight to 35 to match input height we set in GenericTheme.jsx
                        // This is a bandaid fix, theming needs to be fixed to resolve these types of issues properly.
                      }}
                    >
                      <img src={menuVertical} />
                    </Button>
                  </AccessControl>
                  <Menu
                    id="menu-button-menuctxSchema"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleContextMenuClose}
                  >
                    <MenuItem
                      id="menu-button-menuctxSchemaAdd"
                      onClick={this.handleContextMenuAddScheme}
                    >
                      Create New Scheme
                    </MenuItem>
                    <MenuItem
                      id="menu-button-menuctxSchemaDelete"
                      onClick={() => this.handleContextMenuDeleteScheme(this.state.selectedScheme)}
                      hidden={!(this.state.billingSchemes.length > 0)}
                    >
                      Remove Current Scheme
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
            <Dialog open={this.state.addNewSchemeDialog} onClose={this.handleAddSchemeDialogClose}>
              {this.renderAddSchemeDialogContent()}
            </Dialog>
            <Dialog
              disableAutoFocus={true}
              open={this.state.itemDialogInfoOpen}
              onClose={this.handleInfoDialogClose}
            >
              {this.renderInfoDialogContent()}
            </Dialog>
            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
              <div>
                <div>
                  <AccessControl
                    requiredAction="write"
                    accessLevel="add/edit_billing"
                    disableOnFalse={true}
                  >
                    <FormControl fullWidth>
                      <div style={{ marginLeft: '3vw' }}>
                        <FormControlLabel
                          label="Same as Office Info"
                          control={
                            <Checkbox
                              id="chksameInfo"
                              checked={this.state.sameAsOffice}
                              onChange={this.handleChange('sameAsOffice')}
                              color="primary"
                              value={sameInfoValue}
                              icon={
                                <CheckBoxOutlineBlankIcon
                                  style={{ fontSize: 24, color: '#8FC3FF' }}
                                  color="secondary"
                                />
                              }
                              checkedIcon={
                                <CheckBoxIcon style={{ fontSize: 24 }} color="primary" />
                              }
                            />
                          }
                        />
                      </div>
                    </FormControl>
                  </AccessControl>
                </div>
                <GenBillingAddress
                  sameAsOfficeInfo={this.state.sameAsOffice}
                  officeAddress={this.state.address}
                  handleObjectChange={this.handleObjectChange}
                  handleRemoveExtraSpaces={this.removeExtraSpaces}
                  handleSaveButton={this.handleSaveButton}
                />
                <GenBillTwo
                  season={this.state.season}
                  billOptsAndRate={this.state.billOptsAndRate}
                  handleChange={this.handleObjectChange}
                />
              </div>
            </Collapse>
            <Divider />
            <EfinFees
              feesInfo={this.state.efinfees}
              openInfoDialog={this.openInfoDialog}
              itemType={BILLING_ITEM_TYPE.EFIN}
            />
            <Divider />
            <FormBilling
              form_type={BILLING_FORM_TYPES.FORM}
              billingSelectTitle="Add Billing to Form(s)"
              billingSelectMessage="Select Forms for Billing"
              addButtonName="Add Form +"
              billingTitleType="Form Billing"
              billingTableTabName="FORM NAME"
              tbRowsFormBilling={this.state.tbRowsFormBilling}
              updateAddedBilling={this.updateTbRowsFormBilling}
              handleChangeTable={this.handleChangeTbForms}
              handleRowDel={this.handleTBFormRowDel}
              itemType={BILLING_ITEM_TYPE.FormWkxLineItem}
              openInfoDialog={this.openInfoDialog}
              defaultSchemeSelected={this.state.selectedScheme !== 0}
              isBusiness={false}
              customTags={this.state.customTags}
            />
            <Divider />
            <FormBilling
              form_type={BILLING_FORM_TYPES.WORKSHEET}
              billingSelectTitle="Add Billing to Worksheet(s)"
              billingSelectMessage="Select Worksheets for Billing"
              addButtonName="Add Worksheet +"
              billingTitleType="Worksheet Billing"
              billingTableTabName="WORKSHEET NAME"
              tbRowsFormBilling={this.state.tbRowsFormBilling}
              updateAddedBilling={this.updateTbRowsFormBilling}
              handleChangeTable={this.handleChangeTbForms}
              handleRowDel={this.handleTBFormRowDel}
              itemType={BILLING_ITEM_TYPE.FormWkxLineItem}
              openInfoDialog={this.openInfoDialog}
              defaultSchemeSelected={this.state.selectedScheme !== 0}
              isBusiness={false}
              customTags={this.state.customTags}
            />
            <Divider />
            <FormBilling
              form_type={BILLING_FORM_TYPES.LINEITEM}
              billingSelectTitle="Add Line Items to Worksheet(s)"
              billingSelectMessage="Select Line Items for Billing"
              addButtonName="Add Line Items +"
              billingTitleType="Line Item Billing"
              billingTableTabName="LINE ITEM"
              tbRowsFormBilling={this.state.tbRowsFormBilling}
              updateAddedBilling={this.updateTbRowsFormBilling}
              handleChangeTable={this.handleChangeTbForms}
              handleRowDel={this.handleTBFormRowDel}
              itemType={BILLING_ITEM_TYPE.FormWkxLineItem}
              openInfoDialog={this.openInfoDialog}
              defaultSchemeSelected={this.state.selectedScheme !== 0}
              isBusiness={false}
              customTags={this.state.customTags}
            />
            <Divider />
            <BillingDiscounts
              tbRows={this.state.tbRowsDiscounts}
              handleChangeTable={this.handleChangeTableDiscount}
              addRow={this.addRow}
              handleRowDel={this.handleTBFormRowDel}
              itemType={BILLING_ITEM_TYPE.DISCOUNT}
              openInfoDialog={this.openInfoDialog}
              getDiscountTypeByKey={this.getDiscountTypeByKey}
              actionsLocked={this.state.selectedScheme === 0}
              customTags={this.state.customTags}
              customCodeDiscounts={this.state.customCodeDiscounts}
              handleUpdateCustomCodeDiscount={this.handleUpdateCustomCodeDiscount}
            />
            <Divider />
            <CustomChanges
              tbRowsCustomCharges={this.state.tbRowsCustomCharges}
              handleChangeTable={this.handleChangeTableCustom}
              addRow={this.addRow}
              handleRowDel={this.handleTBFormRowDel}
              itemType={BILLING_ITEM_TYPE.CUSTOM}
              openInfoDialog={this.openInfoDialog}
              actionsLocked={this.state.selectedScheme === 0}
            />
            <Divider />
            <CustomSettings
              ancillaryProducts={
                this.props.billingInfo.ancillary_products == null
                  ? []
                  : this.props.billingInfo.ancillary_products
              }
            />
            <Divider />
            {this.props.billingInfo.pprInfo && (
              <Fragment>
                <PPRCharges pprInfo={this.props.billingInfo.pprInfo} />
                <Divider />
              </Fragment>
            )}
            <div
              style={{
                textAlign: 'right',
                width: '100%',
                padding: '0',
                marginLeft: '0vw',
                marginRight: '3vw',
                marginTop: '3vh',
              }}
            >
              <Button
                id="btnPreviousGenBillingSetup"
                color="primary"
                style={{
                  width: '10vw',
                  color: '#0077FF',
                  textTransform: 'capitalize',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  border: '1px solid #0077FF',
                  borderRadius: '4px',
                  marginRight: '1vw',
                }}
                onClick={() => {
                  this.setState({ gotoPrev: true });
                  this.toggleConfirmCancel(true);
                }}
              >
                Previous (cancel)
              </Button>
              <span>
                <Button
                  id="btnCancelGenBillingSetup"
                  color="primary"
                  style={{
                    width: '10vw',
                    color: '#0077FF',
                    textTransform: 'capitalize',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    border: '1px solid #0077FF',
                    borderRadius: '4px',
                    marginRight: '1vw',
                  }}
                  onClick={() => {
                    this.setState({ gotoPrev: false });
                    this.toggleConfirmCancel(true);
                  }}
                >
                  Cancel
                </Button>
                <AccessControl
                  requiredAction="write"
                  accessLevel="add/edit_billing"
                  disableOnFalse={true}
                >
                  <Button
                    id="btnSaveBillingSetup"
                    color="primary"
                    style={{
                      width: '10vw',
                      textTransform: 'capitalize',
                      fontFamily: 'Roboto',
                      fontSize: '14px',
                      borderRadius: '4px',
                      marginRight: '1vw',
                    }}
                    onClick={() => this.handleBillingSave(false)}
                  >
                    Save
                  </Button>
                </AccessControl>
              </span>
              <AccessControl
                requiredAction="write"
                accessLevel="add/edit_billing"
                disableOnFalse={true}
              >
                <Button
                  id="btnNextBillingSetup"
                  color="primary"
                  style={{
                    width: '10vw',
                    textTransform: 'capitalize',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    borderRadius: '4px',
                  }}
                  onClick={() => this.handleBillingSave(true)}
                >
                  Save &amp; Next
                </Button>
              </AccessControl>
            </div>
          </form>
          <br />
        </Paper>
        <SimpleDialog
          open={this.state.confirmCancel}
          onClose={() => this.toggleConfirmCancel(false)}
          onConfirm={() => {
            this.toggleConfirmCancel(false);
            if (this.state.gotoPrev) {
              this.props.loadSetupComponent(REQUIRED_SETUP_PAGES.PREPARER.NAME);
            } else {
              this.getSelectedScheme(this.state.selectedScheme);
            }
          }}
          dialogTitle={'Lose Unsaved Changes?'}
          contentText={'Are you sure you want to undo any pending changes?'}
        />
        <Dialog
          open={this.state.show}
          onClose={this.cancelShow}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Billing Scheme Missing'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {messageBillingSetup}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelShow} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <SimpleDialog
          open={this.state.isStateTaxDisclaimerOpen}
          onConfirm={() => this.upsertBillingScheme(this.state.shouldGoToNextPage)}
          onClose={() => this.handleStateDisclaimerDialogClose()}
          dialogTitle="Sales Tax Rate Disclaimer"
          contentText={messageStateTax}
          cancelText="Close"
          confirmText="Acknowledge"
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GenBillingSetup));
