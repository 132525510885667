// External Import
import React, { Component } from 'react';
import {
  InputLabel,
  Input,
  FormControl,
  FormControlLabel,
  Checkbox,
  withStyles,
  Select,
  MenuItem,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import NumberFormat from 'react-number-format';
// Internal Import
import AccessControl from '#/Auth/AccessControl.jsx';
import infoIcon from '~/images/icons/icons8-info.svg';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
// Styling imports
import { styles } from '#/NewAccount/SingleOffice/css/genBillTwo.js';

class GenBillTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Modals
      isInfoStateTaxRateOpen: false,
    };
  }

  // Handle onChange without validation for input fields
  handleInputChange(e) {
    this.setState({ [e.target.id]: e.target.value });
  }

  // Handle onChange without validation for checkbox
  handleCheckbox = event => {
    this.setState({ [event.target.id]: event.target.checked });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleInfoModal = () => {
    this.setState({ isInfoStateTaxRateOpen: !this.state.isInfoStateTaxRateOpen });
  };

  render() {
    const priorYearBalanceValue = this.props.billOptsAndRate.priorYearBalance ? 'true' : 'false';
    const priorYearOverPaymentValue = this.props.billOptsAndRate.priorYearOverPayment
      ? 'true'
      : 'false';
    const noBillStandardDeductionValue = this.props.billOptsAndRate.noBillStandardDeduction
      ? 'true'
      : 'false';
    const defaultDisbursementValue = this.props.billOptsAndRate.bankDisbursementOption;
    const preventTransmitValue = this.props.billOptsAndRate.preventTransmit ? 'true' : 'false';
    const validatePriorYearBalanceValue = this.props.billOptsAndRate.validatePriorYearBalance
      ? 'true'
      : 'false';
    const disableInvoicingValue = this.props.billOptsAndRate.disableInvoicing ? 'true' : 'false';
    const billOnlyEFValue = this.props.billOptsAndRate.billOnlyEF ? 'true' : 'false';
    const taxBillingsValue = this.props.billOptsAndRate.taxBillings ? 'true' : 'false';
    const noPriorYearPrepFeeValue = this.props.billOptsAndRate.noPriorYearPrepFee
      ? 'true'
      : 'false';
    const disableInvoiceWarningErrorsValue = this.props.billOptsAndRate.disableInvoiceWarningErrors
      ? 'true'
      : 'false';

    const { classes } = this.props;

    return (
      <AccessControl requiredAction="write" accessLevel="add/edit_billing" disableOnFalse={true}>
        <div>
          <div style={{ float: 'left' }}>
            <div style={{ marginLeft: '3vw' }}>
              <FormControl required>
                <InputLabel
                  classes={{ root: classes.labelWidthStyle }}
                  required={false}
                  htmlFor="txtStateSalesTaxGenBillTwo"
                  shrink
                >
                  State Sales Tax Rate
                  <img
                    id="infoStateSalesTax"
                    onClick={this.handleInfoModal}
                    className="state-sales-tax-info-btn"
                    src={infoIcon}
                    style={{ marginLeft: '.4rem' }}
                  />
                </InputLabel>
                <NumberFormat
                  id="txtStateSalesTaxGenBillTwo"
                  value={this.props.billOptsAndRate.stateSalesTax || ''}
                  onChange={e =>
                    this.props.handleChange(
                      'billOptsAndRate',
                      'stateSalesTax',
                      e.target.value.replace(/[$,%]/g, ''),
                    )
                  }
                  classes={{ root: classes.fieldStyle }}
                  disableUnderline
                  customInput={Input}
                  fixedDecimalScale={true}
                  decimalScale={3}
                  suffix="%"
                  isAllowed={values => {
                    const { floatValue } = values;
                    return (
                      (floatValue >= 0.0001 && floatValue <= 99.999) || values.formattedValue === ''
                    );
                  }}
                />
              </FormControl>
            </div>
            <div style={{ marginLeft: '3vw' }}>
              <FormControl required>
                <InputLabel
                  classes={{ root: classes.labelWidthStyle }}
                  required={false}
                  htmlFor="txtSelfPreparedRateGenBillTwo"
                  shrink
                >
                  Self Prepared Flat Rate
                </InputLabel>
                <NumberFormat
                  id="txtSelfPreparedRateGenBillTwo"
                  value={this.props.billOptsAndRate.selfPreparedRate || ''}
                  onChange={e =>
                    this.props.handleChange(
                      'billOptsAndRate',
                      'selfPreparedRate',
                      e.target.value.replace(/[$,%]/g, ''),
                    )
                  }
                  classes={{ root: classes.fieldStyle }}
                  disableUnderline
                  customInput={Input}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix="$"
                  thousandSeparator={true}
                />
              </FormControl>
            </div>
            {this.props.season > 2024 && (
              <div style={{ marginLeft: '3vw' }}>
                <FormControl>
                  <InputLabel
                    required={false}
                    classes={{ root: classes.labelWidthStyle }}
                    id="txtBankDisbursementGenBillTwo"
                    shrink
                  >
                    Default Bank App Disbursement Options
                  </InputLabel>
                  <Select
                    value={defaultDisbursementValue}
                    classes={{ root: classes.fieldStyleSelect }}
                    id="txtBankDisbursementGenBillTwo"
                    onChange={e =>
                      this.props.handleChange(
                        'billOptsAndRate',
                        'bankDisbursementOption',
                        e.target.value,
                      )
                    }
                    input={
                      <Input
                        name="Disbursement_type_id"
                        id="Disbursementtype-selected"
                        disableUnderline
                        classes={{ root: classes.fieldStyle }}
                      />
                    }
                  >
                    {['None', 'Direct Deposit', 'Card', 'Check'].map((item, index) => {
                      return (
                        <MenuItem key={'disbursementTypeDropDownMenu' + index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            )}
            <div>
              <FormControl>
                <div style={{ marginLeft: '3vw' }}>
                  <FormControlLabel
                    label="No prior year balance on invoice"
                    classes={{ root: classes.labelWidthStyleCheckbox }}
                    control={
                      <Checkbox
                        id="chkPriorYearBalanceGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.priorYearBalance == null
                            ? false
                            : this.props.billOptsAndRate.priorYearBalance
                        }
                        onChange={e =>
                          this.props.handleChange(
                            'billOptsAndRate',
                            'priorYearBalance',
                            e.target.checked,
                          )
                        }
                        color="primary"
                        value={priorYearBalanceValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            classes={{ root: classes.checkBoxBlank }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            {this.props.season > 2022 && (
              <div>
                <FormControl>
                  <div style={{ marginLeft: '3vw' }}>
                    <FormControlLabel
                      label="No prior year overpayment on invoice"
                      classes={{ root: classes.labelWidthStyleCheckbox }}
                      control={
                        <Checkbox
                          id="chkPriorYearOverPaymentGenBillTwo"
                          checked={
                            this.props.billOptsAndRate.priorYearOverPayment == null
                              ? false
                              : this.props.billOptsAndRate.priorYearOverPayment
                          }
                          onChange={e =>
                            this.props.handleChange(
                              'billOptsAndRate',
                              'priorYearOverPayment',
                              e.target.checked,
                            )
                          }
                          color="primary"
                          value={priorYearOverPaymentValue}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              classes={{ root: classes.checkBoxBlank }}
                              color="secondary"
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        />
                      }
                    />
                  </div>
                </FormControl>
              </div>
            )}
            <div>
              <FormControl>
                <div style={{ marginLeft: '3vw' }}>
                  <FormControlLabel
                    label="Don't bill for Sch A when using STD deduction"
                    classes={{ root: classes.labelWidthStyleCheckbox }}
                    control={
                      <Checkbox
                        id="chkNoBillStandardDeductionGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.noBillStandardDeduction == null
                            ? false
                            : this.props.billOptsAndRate.noBillStandardDeduction
                        }
                        onChange={e =>
                          this.props.handleChange(
                            'billOptsAndRate',
                            'noBillStandardDeduction',
                            e.target.checked,
                          )
                        }
                        color="primary"
                        value={noBillStandardDeductionValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            classes={{ root: classes.checkBoxBlank }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            {this.props.season < 2025 && (
              <div>
                <FormControl>
                  <div style={{ marginLeft: '3vw' }}>
                    <FormControlLabel
                      label="Turn off default Bank App disbursement options"
                      classes={{ root: classes.labelWidthStyleCheckbox }}
                      control={
                        <Checkbox
                          id="chkBankDisbursementOptionGenBillTwo"
                          checked={
                            this.props.billOptsAndRate.bankDisbursementOption === 'true'
                              ? Boolean(this.props.billOptsAndRate.bankDisbursementOption)
                              : false
                          }
                          onChange={e =>
                            this.props.handleChange(
                              'billOptsAndRate',
                              'bankDisbursementOption',
                              e.target.checked,
                            )
                          }
                          color="primary"
                          value={defaultDisbursementValue}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              classes={{ root: classes.checkBoxBlank }}
                              color="secondary"
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        />
                      }
                    />
                  </div>
                </FormControl>
              </div>
            )}
            <div>
              <FormControl>
                <div style={{ marginLeft: '3vw' }}>
                  <FormControlLabel
                    label="Prevent transmit on returns with a balance due fees"
                    classes={{ root: classes.labelWidthStyleCheckbox }}
                    control={
                      <Checkbox
                        id="chkPreventTransmitGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.preventTransmit == null
                            ? false
                            : this.props.billOptsAndRate.preventTransmit
                        }
                        onChange={e =>
                          this.props.handleChange(
                            'billOptsAndRate',
                            'preventTransmit',
                            e.target.checked,
                          )
                        }
                        color="primary"
                        value={preventTransmitValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            classes={{ root: classes.checkBoxBlank }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>

            <div>
              <FormControl>
                <div style={{ marginLeft: '3vw' }}>
                  <FormControlLabel
                    label="Validate the prior year balance"
                    classes={{ root: classes.labelWidthStyleCheckbox }}
                    control={
                      <Checkbox
                        id="chkValidatePriorYearBalance"
                        checked={
                          this.props.billOptsAndRate.validatePriorYearBalance == null
                            ? false
                            : this.props.billOptsAndRate.validatePriorYearBalance
                        }
                        onChange={e =>
                          this.props.handleChange(
                            'billOptsAndRate',
                            'validatePriorYearBalance',
                            e.target.checked,
                          )
                        }
                        color="primary"
                        value={validatePriorYearBalanceValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            classes={{ root: classes.checkBoxBlank }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
          </div>

          <div style={{ float: 'none', marginLeft: '35vw' }}>
            <div>
              <FormControl required>
                <InputLabel
                  classes={{ root: classes.labelWidthStyle }}
                  required={false}
                  htmlFor="txtTaxDiscountGenBillTwo"
                  shrink
                >
                  Tax Preparation Discount
                </InputLabel>
                <NumberFormat
                  id="txtTaxDiscountGenBillTwo"
                  value={this.props.billOptsAndRate.taxDiscount || ''}
                  onChange={e =>
                    this.props.handleChange(
                      'billOptsAndRate',
                      'taxDiscount',
                      e.target.value.replace(/[$,%]/g, ''),
                    )
                  }
                  classes={{ root: classes.fieldStyle }}
                  disableUnderline
                  customInput={Input}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix="$"
                  thousandSeparator={true}
                />
              </FormControl>
            </div>
            <div>
              <FormControl required>
                <InputLabel
                  classes={{ root: classes.labelWidthStyle }}
                  required={false}
                  htmlFor="txtHourlyRateGenBillTwo"
                  shrink
                >
                  Default Hourly Rate
                </InputLabel>
                <NumberFormat
                  id="txtHourlyRateGenBillTwo"
                  value={this.props.billOptsAndRate.hourlyRate || ''}
                  onChange={e =>
                    this.props.handleChange(
                      'billOptsAndRate',
                      'hourlyRate',
                      e.target.value.replace(/[$,%]/g, ''),
                    )
                  }
                  classes={{ root: classes.fieldStyle }}
                  disableUnderline
                  customInput={Input}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  prefix="$"
                  thousandSeparator={true}
                />
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div>
                  <FormControlLabel
                    label="Disable invoicing"
                    classes={{ root: classes.labelWidthStyleCheckbox }}
                    control={
                      <Checkbox
                        id="chkDisableInvoicingGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.disableInvoicing == null
                            ? false
                            : this.props.billOptsAndRate.disableInvoicing
                        }
                        onChange={e =>
                          this.props.handleChange(
                            'billOptsAndRate',
                            'disableInvoicing',
                            e.target.checked,
                          )
                        }
                        color="primary"
                        value={disableInvoicingValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            classes={{ root: classes.checkBoxBlank }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div>
                  <FormControlLabel
                    label="Bill for electronically filed forms only"
                    classes={{ root: classes.labelWidthStyleCheckbox }}
                    control={
                      <Checkbox
                        id="chkBillOnlyEFGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.billOnlyEF == null
                            ? false
                            : this.props.billOptsAndRate.billOnlyEF
                        }
                        onChange={e =>
                          this.props.handleChange('billOptsAndRate', 'billOnlyEF', e.target.checked)
                        }
                        color="primary"
                        value={billOnlyEFValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            classes={{ root: classes.checkBoxBlank }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div>
                  <FormControlLabel
                    label="Collect tax on billings?"
                    classes={{ root: classes.labelWidthStyleCheckbox }}
                    control={
                      <Checkbox
                        id="chkTaxBillingsGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.taxBillings == null
                            ? false
                            : this.props.billOptsAndRate.taxBillings
                        }
                        onChange={e =>
                          this.props.handleChange(
                            'billOptsAndRate',
                            'taxBillings',
                            e.target.checked,
                          )
                        }
                        color="primary"
                        value={taxBillingsValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            classes={{ root: classes.checkBoxBlank }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <div>
                  <FormControlLabel
                    label="No prior year preparation fee on invoice"
                    classes={{ root: classes.labelWidthStyleCheckbox }}
                    control={
                      <Checkbox
                        id="chkNoPriorYearPrepFeeGenBillTwo"
                        checked={
                          this.props.billOptsAndRate.noPriorYearPrepFee == null
                            ? false
                            : this.props.billOptsAndRate.noPriorYearPrepFee
                        }
                        onChange={e =>
                          this.props.handleChange(
                            'billOptsAndRate',
                            'noPriorYearPrepFee',
                            e.target.checked,
                          )
                        }
                        color="primary"
                        value={noPriorYearPrepFeeValue}
                        icon={
                          <CheckBoxOutlineBlankIcon
                            classes={{ root: classes.checkBoxBlank }}
                            color="secondary"
                          />
                        }
                        checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                      />
                    }
                  />
                </div>
              </FormControl>
            </div>
            {this.props.season > 2020 && (
              <div>
                <FormControl>
                  <div>
                    <FormControlLabel
                      label="Disable invoice warning errors"
                      classes={{ root: classes.labelWidthStyleCheckbox }}
                      control={
                        <Checkbox
                          id="chkDisableInvoiceWarningErrorsGenBillTwo"
                          checked={
                            this.props.billOptsAndRate.disableInvoiceWarningErrors == null
                              ? false
                              : this.props.billOptsAndRate.disableInvoiceWarningErrors
                          }
                          onChange={e =>
                            this.props.handleChange(
                              'billOptsAndRate',
                              'disableInvoiceWarningErrors',
                              e.target.checked,
                            )
                          }
                          color="primary"
                          value={disableInvoiceWarningErrorsValue}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              classes={{ root: classes.checkBoxBlank }}
                              color="secondary"
                            />
                          }
                          checkedIcon={<CheckBoxIcon style={{ fontSize: 24 }} color="primary" />}
                        />
                      }
                    />
                  </div>
                </FormControl>
              </div>
            )}
          </div>
          <br />
          <SimpleDialog
            open={this.state.isInfoStateTaxRateOpen}
            dialogTitle="State Sales Tax Rate Field"
            onConfirm={this.handleInfoModal}
            contentText="Please insert the applicable state or local tax rate in this field. If both a state and local tax are applicable, please combine them and enter the total amount in this field"
            styled={true}
          />
        </div>
      </AccessControl>
    );
  }
}

export default withStyles(styles)(GenBillTwo);
